/**
 * @file CompanyDetailsTemplate Component
 * @description Displays detailed information about a company including their services,
 * location, contact form, and similar companies. This template is used for individual
 * company pages in the business directory.
 */

import { Link, graphql } from 'gatsby'
import React, { useCallback, useMemo, useRef } from 'react'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'

// Local Components and Types
import theme from '../components/atoms/theme'
import Container from '../components/container'
import Layout from '../components/layout'
import Button from '../components/molecules/button'
import DirectoryListings from '../components/organisms/directory/directory-listings'
import { FullWidthContainer } from '../components/organisms/page-hero-slider'
import { Company, CompanyDetails } from '../types/company'

// Assets
const TextureSrc = require('../assets/texture.svg') as string

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
  pageContext: {
    company: CompanyDetails
    similarCompanies: Company[]
  }
  location: {
    pathname: string
  }
}

/**
 * ChevronIcon Component
 * @param {Object} props - Component props
 * @param {string} [props.className] - Optional CSS class name
 * @returns {JSX.Element} Rendered chevron icon
 */
const ChevronIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 9 14'
      fill='none'
      className={className}
      role='img'
      aria-label='Chevron Icon'
    >
      <path
        d='M1.16121 -0.00512695C1.0617 -0.00510268 0.964466 0.024609 0.881939 0.0802066C0.799413 0.135804 0.735351 0.214759 0.69795 0.30697C0.660548 0.399181 0.651507 0.500453 0.671985 0.597831C0.692463 0.695208 0.741529 0.784261 0.812903 0.853597L6.95939 7.00008L0.812903 13.1466C0.764917 13.1926 0.726607 13.2478 0.700215 13.3089C0.673824 13.37 0.659882 13.4357 0.659206 13.5022C0.658529 13.5687 0.671133 13.6347 0.696277 13.6963C0.721422 13.7579 0.758602 13.8138 0.805641 13.8609C0.85268 13.9079 0.908632 13.9451 0.97022 13.9702C1.03181 13.9954 1.09779 14.008 1.16431 14.0073C1.23083 14.0066 1.29655 13.9927 1.35761 13.9663C1.41868 13.9399 1.47386 13.9016 1.51993 13.8536L8.01994 7.3536C8.11367 7.25982 8.16632 7.13267 8.16632 7.00008C8.16632 6.8675 8.11367 6.74034 8.01994 6.64657L1.51993 0.146566C1.47333 0.0985704 1.41757 0.0604148 1.35595 0.0343587C1.29433 0.00830263 1.22811 -0.00512429 1.16121 -0.00512695Z'
        fill='currentColor'
      />
    </svg>
  )
}

// Styled Components
const Header = styled.div`
  background-image: url(${TextureSrc});
  padding: 2rem 0;
  color: ${theme.colours.white};
  margin-bottom: 8rem;
`

const MobileButtonWrapper = styled.div`
  display: none;
  margin-top: 2rem;

  @media ${theme.breakpoints.toMediumScreen} {
    display: block;
  }
`

const Breadcrumbs = styled.nav`
  margin-bottom: 4rem;
  font-size: 1.4rem;
  color: ${theme.colours.white};
  display: flex;
  align-items: center;
  gap: 1.2rem;

  a {
    color: ${theme.colours.white};
    text-decoration: none;
    font-size: 1.4rem;
    
    &:hover {
      text-decoration: underline;
    }
  } 
`

const CompanyHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
`

const Logo = styled.img`
  width: 14rem;
  height: 14rem;
  padding: 0.8rem;
  border-radius: 2.9rem;
  object-fit: contain;
  background: ${theme.colours.white};
`

const CompanyInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`

const CompanyName = styled.h1`
  margin: 0;
  font-family: ${theme.fonts.secondary};
  font-weight: 500;
  font-size: 3.2rem;
  color: ${theme.colours.white};
`

const Location = styled.div`
  margin: 0;
  font-size: 1.6rem;
  color: ${theme.colours.white};
  opacity: 0.8;

  a {
    color: inherit;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

const Description = styled.div`
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey2};
  
  p {
    margin: 0 0 1.6rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${theme.colours.primary};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  ul, ol {
    margin: 0 0 1.6rem;
    padding-left: 2rem;
  }
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin-top: 0.6rem;
`

const CategoryTag = styled.span`
  border-radius: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  color: ${theme.colours.white};
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  line-height: 1;
`

const ContentSection = styled.section`
  padding: 2.4rem;
  border: 1px solid ${theme.colours.gray3};
  margin-bottom: 4rem;
  border-radius: 1.6rem;
`

const StyledChevronIcon = styled(ChevronIcon)`
  width: 1.6rem;
  height: 1.6rem;
`

const SectionTitle = styled.h2`
  font-family: ${theme.fonts.primaryBlack};
  font-size: 3.2rem;
  color: ${theme.colours.secondary};
  margin: 0 0 2rem;
`

const ServicesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const ServiceItem = styled.li`
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey2};
  margin-bottom: 1.2rem;
  padding-left: 2rem;
  position: relative;

  &:before {
    content: "•";
    position: absolute;
    left: 0;
  }
`

const LocationDetails = styled.div`
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey2};
  
  p {
    margin: 0 0 1.2rem;
  }

  a {
    color: inherit;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 40rem;
  gap: 4rem;
  align-items: start;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const MainContent = styled.main``

const Sidebar = styled.aside`
  position: sticky;
  top: 12rem;
  height: fit-content;
  max-height: calc(100vh - 12rem);
  overflow-y: auto;
  margin-bottom: 4rem;
  overflow: visible;

  @media (max-width: 1024px) {
    position: static;
    height: auto;
    max-height: none;
    overflow-y: visible;
  }
`

const FormGroup = styled.div`
  margin-bottom: 2.4rem;
`

const Label = styled.label`
  display: block;
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey};
  margin-bottom: 0.8rem;
`

const BoldText = styled.span`
  font-family: ${theme.fonts.primaryBold};
  color: ${theme.colours.secondary};
`

const Select = styled.select`
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  border: 2px solid ${theme.colours.gray3};
  border-radius: 0.4rem;
  background: ${theme.colours.white};
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1.2rem center;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${theme.colours.primary};
  }
`

const Input = styled.input`
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  border: 2px solid ${theme.colours.gray3};
  border-radius: 0.4rem;
  background: ${theme.colours.white};

  &:focus {
    outline: none;
    border-color: ${theme.colours.primary};
  }
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  border: 2px solid ${theme.colours.gray3};
  border-radius: 0.4rem;
  background: ${theme.colours.white};
  min-height: 12rem;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${theme.colours.primary};
  }
`

const ErrorMessage = styled.div`
  color: ${theme.colours.danger1};
  font-size: 1.4rem;
  margin-top: 0.8rem;
`

/**
 * Generates a Google Maps URL for a given location
 * @param {string} location - The location address
 * @returns {string} Google Maps URL
 */
const getGoogleMapsUrl = (location: string): string => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`
}

/**
 * CompanyDetailsTemplate Component
 * @param {Props} props - Component props
 * @returns {JSX.Element} Rendered company details page
 */
const CompanyDetailsTemplate = ({
  pageContext,
  location,
  data,
}: Props): JSX.Element => {
  const { company } = pageContext
  const siteTitle = data.site.siteMetadata.title
  const currentPath = location.pathname
  const formRef = useRef<HTMLDivElement>(null)

  // Memoized values
  const fullAddress = useMemo(
    () => `${company.street}, ${company.city} ${company.postcode}`,
    [company.street, company.city, company.postcode],
  )

  const scrollToForm = useCallback(() => {
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [])

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={`${company.companyName} | ${siteTitle}`} />
      <FullWidthContainer>
        <Header>
          <Container>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link to='/'>Home</Link>
              <StyledChevronIcon />
              <Link to='/directory'>Business Directory</Link>
              <StyledChevronIcon />
              {company.category && <div>{company.category}</div>}
              <StyledChevronIcon />
              <span aria-current='page'>{company.companyName}</span>
            </Breadcrumbs>
            <CompanyHeader>
              {company.companyLogoURL && (
                <Logo
                  src={company.companyLogoURL}
                  alt={`${company.companyName} logo`}
                  loading='lazy'
                  onError={(e) => {
                    e.currentTarget.style.display = 'none'
                  }}
                />
              )}
              <CompanyInfo>
                <CompanyName>{company.companyName}</CompanyName>
                <Location>
                  <a
                    href={getGoogleMapsUrl(fullAddress)}
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label={`View ${company.companyName}'s location on Google Maps`}
                  >
                    {company.city}
                  </a>
                </Location>
                <TagsContainer>
                  {company.category && (
                    <CategoryTag>{company.category}</CategoryTag>
                  )}
                </TagsContainer>
              </CompanyInfo>
            </CompanyHeader>
            <MobileButtonWrapper>
              <Button
                onClick={scrollToForm}
                visualStyle='Primary'
                visualType='Solid'
                aria-label='Scroll to enquiry form'
              >
                Submit an enquiry
              </Button>
            </MobileButtonWrapper>
          </Container>
        </Header>
      </FullWidthContainer>

      <ContentLayout>
        <MainContent>
          <ContentSection>
            <SectionTitle>About us</SectionTitle>
            <Description>
              {company.aboutUs ? ReactHtmlParser(company.aboutUs) : null}
            </Description>
          </ContentSection>

          <ContentSection>
            <SectionTitle>Services and products</SectionTitle>
            <ServicesList>
              {company.servicesProducts
                ?.split(';')
                .slice(0, 3)
                .map((service, index) => (
                  <ServiceItem key={`${service.trim()}-${index}`}>
                    {service.trim()}
                  </ServiceItem>
                ))}
            </ServicesList>
          </ContentSection>

          <ContentSection>
            <SectionTitle>Location</SectionTitle>
            <LocationDetails>
              <p>
                Located in{' '}
                <a
                  href={getGoogleMapsUrl(fullAddress)}
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label={`View ${company.companyName}'s location on Google Maps`}
                >
                  <BoldText>{fullAddress}</BoldText>
                </a>
              </p>
              <p>
                Working Hours: <BoldText>{company.businessHours}</BoldText>
              </p>
            </LocationDetails>
          </ContentSection>

          <ContentSection ref={formRef}>
            <SectionTitle>Submit an enquiry</SectionTitle>
            <form
              action='https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DOg000000m3IH'
              method='POST'
            >
              <input type='hidden' name='oid' value='00DOg000000m3IH' />
              <input
                type='hidden'
                name='lead_source'
                value='Website Directory'
              />
              <input
                type='hidden'
                name='Website_Directory_Listing_ID__c'
                value={company.id}
              />
              <input
                type='hidden'
                name='retURL'
                value={`https://pacificbusiness.co.nz${currentPath}`}
              />

              <FormGroup>
                <Label htmlFor='salutation'>Salutation</Label>
                <Select id='salutation' name='salutation'>
                  <option value=''>--None--</option>
                  <option value='Miss'>Miss</option>
                  <option value='Mr.'>Mr.</option>
                  <option value='Mrs.'>Mrs.</option>
                  <option value='Ms.'>Ms.</option>
                  <option value='Dr.'>Dr.</option>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label htmlFor='first_name'>First Name*</Label>
                <Input
                  type='text'
                  id='first_name'
                  name='first_name'
                  maxLength={40}
                  required
                  aria-required='true'
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor='last_name'>Last Name*</Label>
                <Input
                  type='text'
                  id='last_name'
                  name='last_name'
                  maxLength={80}
                  required
                  aria-required='true'
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor='email'>Email*</Label>
                <Input
                  type='email'
                  id='email'
                  name='email'
                  maxLength={80}
                  required
                  aria-required='true'
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor='00NMo000001OMwT'>Subject*</Label>
                <Input
                  type='text'
                  id='00NMo000001OMwT'
                  name='00NMo000001OMwT'
                  maxLength={255}
                  required
                  aria-required='true'
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor='00NMo000001OMy5'>Message*</Label>
                <TextArea
                  id='00NMo000001OMy5'
                  name='00NMo000001OMy5'
                  rows={5}
                  required
                  aria-required='true'
                />
              </FormGroup>

              <Button type='submit' visualStyle='Primary' visualType='Solid'>
                Send message
              </Button>
            </form>
          </ContentSection>
        </MainContent>

        <Sidebar>
          <SectionTitle>Similar Companies</SectionTitle>
          <DirectoryListings companies={pageContext.similarCompanies} />
        </Sidebar>
      </ContentLayout>
    </Layout>
  )
}

export default CompanyDetailsTemplate

export const pageQuery = graphql`
  query CompanyDetailsQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
